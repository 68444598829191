.productsTitle {
    text-align: center;

    margin-bottom: 50px;
    font-size: 3rem;
}

.tab-button {
    color: rgb(102, 102, 102) !important;
    font-weight: 500;
    background-color: transparent;
    border: none;
    transition: 1s;
}

.tab-button:hover {
    color: var(--primaryColor) !important;
    scale: 1.1;
    border-bottom: 1px solid var(--primaryColor);
}

.active-tab-button {
    color: var(--primaryColor) !important;
    scale: 1.1;
    border-bottom: 1px solid var(--primaryColor);
}

.product-image {
    position: relative;
    padding: 0px;
    transition: 1.5s ease;
    margin-top: 20px;
    animation: scaleUp 1s ease-in-out forwards;
}

@keyframes scaleUp {
    from {
        transform: scale(0.6);
        /* Start at 60% scale */
    }

    to {
        transform: scale(1);
        /* End at 100% scale */
    }
}

.product-name-overlay {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px;
    width: 95%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: 1.5s ease;
}

.product-image:hover {
    scale: 1.05;
    cursor: pointer;
}

.product-image img {
    border-radius: 10px;
}

.product-image:hover .product-name-overlay {
    opacity: 1;
}

.product-name {
    font-weight: bold;
    font-size: 22px;
}

.see-all-button {
    background-color: var(--primaryColor);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.8s ease;
}

.see-all-button:hover {
    background-color: var(--primaryColor);
    scale: 1.1;
}

.modal-content {
    background-color: transparent !important;
    border: none;
    color: white;

}

.modal-dialog {
    max-width: 1000px;
    width: 35%;
    height: 35%;
    margin: 45px auto !important;
    padding: 0 !important;
}

.modal-dialog img {
    width: 100%;
    height: auto;
}

.modal-body {
    padding: 2rem;
}

.closeModalIcon:hover {
    cursor: pointer;
}

.modal-backdrop {
    background-color: rgb(0, 0, 0);
    opacity: .75 !important;
}