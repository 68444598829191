

  
  .about-us-title {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .about-us-text {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  
  .about-us-button {
    background-color:var(--primaryColor);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
    
    transition:0.8s ease;
  }
  
  .about-us-button:hover {
    background-color: var(--primaryColor);
    scale: 1.1;
  }
  

  
  .about-us-image img {
    width: 100%;
    height: 100%;
  }