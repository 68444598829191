nav{
background-color: white ;

box-shadow: 1px 1px 0px 0px rgba(168,168,168,0.8);
-webkit-box-shadow: 1px 1px 0px 0px rgba(168,168,168,0.8);
-moz-box-shadow: 1px 1px 0px 0px rgba(168,168,168,0.8);
}

.nav-link{
    color: rgb(102, 102, 102) !important;
    font-weight: 500;
    transition: 1s;
}

.nav-link:hover{
    color:var(--primaryColor)  !important;
    scale: 1.1;
    border-bottom: 1px solid var(--primaryColor);
}
.activeNavLink{
    color:var(--primaryColor)  !important;
    scale: 1.1;
    border-bottom: 1px solid var(--primaryColor);
}