footer{
    background-color: var(--primaryColor);
}
.contact-us-button {
    background-color:#fff;
    color: var(--primaryColor);
    border: none;
    padding: 10px 20px;
    margin: 10px auto;
    border-radius: 15px;
    cursor: pointer;
    
    transition:0.8s ease;
  }
  
  .contact-us-button:hover {
    
    scale: 1.1;
  }
.social-icon {
    color: white;
    font-size: 25px;
    padding: 7px;
    border-radius: 5px;
    background-color: var(--primaryColor);

}
