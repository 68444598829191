
  
  /* Tablet */
  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    .carousel {
        margin-top: 100px;
      }
      .carousel img{
         height:  600px;
         
        }
      .carousel-arrow {
          width: 50px;
          height: 50px;
          border-radius: 50px;
          text-align: center;
          background-color: var(--primaryColor);
      }
  }
  
  /* Desktop */
  @media only screen and (min-width:1000px) {
    .carousel-arrow {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        text-align: center;
        background-color: var(--primaryColor);
    }
    
    .carousel img{
        height:  100vh;
        
       }
    .carousel {
        margin-top: 20px;
      }
  }
  
  /* Mobile */
  @media only screen and (max-width: 599px) {
    .carousel {
      margin-top: 100px;
    }
    .carousel img{
       height:  350px;
       
      }
    .carousel-arrow {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        background-color: var(--primaryColor);
    }
  }