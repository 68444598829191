:root {
    --primaryColor: #d52029;
    --white: #ffffff;
  }

.temp{
height: 1500px;
width: 100%;
background-color: green;
}

h1,h2,h3,h4,h5,h6{
font-family: fantasy;

}


ul{list-style: none;}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 2000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 2000ms;
}
