.mainDiv {
    padding-bottom: 150px;
    padding-top: 170px;
    width: 100%;
    background-color: #f0f0f0;

}

.mainDiv h1 {
    text-align: center;
    font-size: 4rem;
    font-family: sans-serif;
    font-weight: 700;
}

.card {
    margin-top: -80px;
    padding: 60px 0px !important;

}

.info-card {
    border: grey solid 1px;
    border-radius: 7px;
    margin: 10px auto;
    width: 90%;
    max-width: 600px;
    /* make it responsive */
    box-sizing: border-box;
}
@media only screen and (min-width: 600px) {
    .info-card {
        width: 31%;  margin: 10px auto;
    }
}
@media only screen and (min-width: 800px) {
    .info-card {
        width: 30%;  margin: 10px auto;
    }
}

.info-card:hover {
    cursor: pointer;
}

.cardIcon {
    color: var(--primaryColor);
    font-size: 45px;
    margin-bottom: 10px;
}

.card-text {
    font-size: 18px;
    margin-top: 5px;
    font-family: sans-serif;
    font-weight: 600;
    color: var(--primaryColor);
}

.formButton {
    width: 120px;
    background-color: var(--primaryColor);
    color: white;
    border: none;
    margin: 5px auto;
    transition: 1s;
}

.formButton:hover {
    scale: 1.1;
    cursor: pointer;
    background-color: var(--primaryColor);
    color: white;
}